<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { Writable } from "svelte/store";
  import { sortBy } from "../util/sort";
  import Loading from "./Loading.svelte";

  const dispatchEvent = createEventDispatcher();

  export let policy: any;
  export let level: string;
  export let availability: Record<
    string,
    {
      level: string;
      "level:ref": string;
      available: number;
      count: number;
      items: Record<string, any>;
    }
  >;
  let byLevel: {
    level: string;
    "level:ref": string;
    available: number;
    count: number;
    items: Record<string, any>;
  };
  let all = false;

  $: allLevels = sortBy(
    Object.entries(availability ?? {}),
    ([level]) => level
  ).map(([spacelevel, item]) => item);

  $: levelsWithAvailablity = allLevels.filter((item) => item.available);

  $: byLevel = availability?.[level];

  $: if (availability && !availability?.[level] && levelsWithAvailablity.length)
    level = levelsWithAvailablity[0].level;

  // $: console.log(
  //   "parking policy = ",
  //   policy,
  //   "level=",
  //   level,
  //   "selected=",
  //   selected
  // );

  function levelclick(e: Event) {
    const button = e.currentTarget as HTMLButtonElement;
    all = false;
    level = button.value;

    dispatchEvent("change", {
      name: button.name,
      value: button.value,
    });
  }
</script>

{#if !policy || !availability}
  <Loading message="Loading available spaces" />
  <!-- {:else if policy && !Object.values(availability).length}
  <Warning
    message="Sorry, there are no spaces available right now. Please check back later."
  /> -->
{:else}
  <!-- <h1>Parking Availability:</h1> -->
  <ul class="levels">
    {#if (all || !byLevel) && allLevels.length > 1}
      {#each allLevels as item}
        <li>
          <!-- <input
          type="radio"
          name="level"
          value={spacelevel + ""}
          checked={level == spacelevel}
          on:change={onchange}
        /> -->
          <h1>
            <data class="level" value={item.level}>{item["level:ref"]}</data>
          </h1>
          <p>
            <data value={item.available}
              >{item.available} of {item.count} space{item.available == 1
                ? ""
                : "s"} available</data
            >
          </p>
          <!-- {#if level != spacelevel} -->
          {#if item.available && allLevels.length > 1}
            <button
              name="level"
              type="button"
              value={item.level + ""}
              on:click={levelclick}>select</button
            >
          {/if}
          <!-- {/if} -->
        </li>
      {/each}
    {:else if byLevel || 1 === allLevels.length}
      <li>
        <h1>
          <data class="level" value={byLevel.level}>{byLevel["level:ref"]}</data
          >
        </h1>
        <p>
          <data value={byLevel.available}
            >{byLevel.available} of {byLevel.count} space{byLevel.available == 1
              ? ""
              : "s"} available</data
          >
        </p>
        {#if allLevels.length > 1}
          <button name="all" type="button" on:click={(e) => (all = true)}
            >change</button
          >
        {/if}
      </li>
    {/if}
  </ul>
{/if}

<!-- form-y stuff -->
