<script lang="ts">
  import RecordItem from "../RecordItem.svelte";
  import Select from "../records/SelectVehicle.svelte";

  export let value = null;
  export let name = "vehicle";
  export let label = "Vehicle";
  export let property = null;
  export let required = false;
  export let scannable = false;
  export let readonly = false;
  export let items = [];

  $: id = (value?.id || value)?.toString();
</script>

{#if id}
  <figure class="value record">
    {#if label}
      <figcaption>{label}</figcaption>
    {/if}
    <input type="hidden" {name} value={id} />
    <RecordItem item={value} url={false} />
    {#if !readonly}
      <button type="button" on:click={(e) => (value = null)}>change</button>
    {/if}
  </figure>
{:else}
  <Select
    {label}
    {scannable}
    {required}
    {items}
    {property}
    placeholder={required ? "Required plate/tag" : "Optional plate/tag"}
    bind:selected={value}
  />
{/if}
