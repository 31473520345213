<script>
  import { startCase } from "lodash-es";
  import { debounce, orderBy, uniqBy } from "lodash-es";
  import MiniSearch from "minisearch";
  import { createEventDispatcher, onDestroy } from "svelte";
  import { comparer } from "../../util/sort";
  //import { identify } from "../../util/api";
  //import MediaScanner from "../camera/MediaScanner.svelte";
  import RecordItem from "../RecordItem.svelte";

  const dispatch = createEventDispatcher();

  export let selected;
  export let required = false;
  export let scannable = false;
  export let label = null;
  export let placeholder = "number";
  let scanning = false;

  export let items = [];
  import { media as match } from "./select";

  //$: console.log("items=", items);

  let type = "media";
  let name = null;
  let searched;
  let results = [];
  let datalist = null;
  let focused = false;
  let all = false;

  //$: console.log("match=", $match);

  const miniSearch = new MiniSearch({
    fields: ["display", "title", "label", "color"],
    searchOptions: {
      prefix: true,
      boost: { display: 2 },
    },
    storeFields: ["id", "key"],
    extractField: (document, fieldName) =>
      fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
  });

  $: itemsById = (items || []).reduce((map, item) => {
    return (map[item.id.toLowerCase()] = item) && map;
  }, {});

  $: console.log("items=", itemsById);

  $: if (items && !placeholder)
    placeholder = [
      ...new Set(items.map((i) => i.format.replace("-", " ").toLowerCase())),
    ].join("/");

  //$:console.log("formats=", [...new Set((items || []).map(i => i.format))]);

  $: searched =
    items &&
    $match &&
    uniqBy(
      orderBy(
        miniSearch.search($match),
        ["score", label],
        ["desc", "asc", "asc", "asc"]
      ),
      "id"
    );
  $: results =
    (searched && searched.map((result) => itemsById[result.id])) ||
    ((focused && items) || (all && items) || []).sort(comparer("display"));

  $: if (results.length == 1) select(results[0].id);

  // $: console.log("results=", results);

  $: if (items) {
    miniSearch.removeAll();
    miniSearch.addAll(items);
  } else {
    miniSearch.removeAll();
  }

  const oninput = debounce(
    function (e) {
      // console.log("suggest=", miniSearch.autoSuggest(e.currentTarget.value));
      // console.log("search=", miniSearch.search(e.currentTarget.value));
      //console.log("about to set match store = ", e.currentTarget.value);
      scanning = false;
      match.set(e.currentTarget.value);
    },
    150,
    {
      leading: true,
      trailing: true,
    }
  );

  $: if (selected) match.set("");

  onDestroy(function () {
    scanning = false;
    match.set("");
  });

  function select(value) {
    scanning = false;
    match.set("");
    dispatch(
      type,
      (selected =
        (value?.toLowerCase && itemsById[value.toLowerCase()]) || value)
    );
  }

  function labeler(item) {
    return item.name || item.display;
  }
  async function onMediaInfotag(e) {
    console.log("onMediaInfotag", e);
    var { id, format } = e.detail;
    console.log("onmedia", id, format, itemsById[id.toLowerCase()]);
    if ("media" !== format) return;
    let media;
    if ((media = itemsById[id.toLowerCase()])) select(media);
    if ((media = itemsById[(await identify(id)).toLowerCase()])) select(media);
  }
  function onscanner(e) {
    scanning = !scanning;
    match.set("");
  }
</script>

<fieldset class="record {type}">
  {#if label}
    <label for="{type}-search">{label}</label>
  {/if}
  <input
    id="{type}-search"
    placeholder={focused ? "type to filter" : placeholder}
    type="text"
    on:input={oninput}
    on:change={oninput}
    autocorrect="off"
    autocapitalize="characters"
    spellcheck="false"
    autocomplete="off"
    required={!!required}
    on:focus={(e) => false && (focused = true)}
    on:blur={(e) => false && (focused = false)}
    list={datalist}
  />
  {#if scannable}
    <button class="scan" class:scanning type="button" on:click={onscanner}
      >Scan</button
    >
  {/if}
  {#if items && items.length}
    <!-- <select on:change={(e) => select(e.currentTarget.value)}>
      <option value="">---</option>
      {#each items as item}
        <option value={item.id}>{labeler(item)}</option>
      {/each}
    </select> -->
    <button type="button" class="list all" on:click={(e) => (all = !all)}
      >List</button
    >
    {#if datalist}
      <datalist id={datalist}>
        {#each items as item}
          <option value={labeler(item)} />
        {/each}
      </datalist>
    {/if}
  {/if}
</fieldset>
<!-- {#if scanning}
  <MediaScanner on:media={onMediaInfotag} />
{/if} -->
<ul class="results">
  {#each results as item}
    <li>
      <button
        class="record"
        type="button"
        value={item.id}
        on:click={(e) => select(e.currentTarget.value)}
        ><RecordItem {item} url={false} /></button
      >
    </li>
  {/each}
</ul>
