<script lang="ts">
  import PropertyPolicySpaceSelector from "./components/PropertyPolicySpaceSelector.svelte";
  import { param } from "./util/params";
  import { property } from "./util/propertystores";
  const view = param("view");
</script>

{#if "selector" === $view}
  <PropertyPolicySpaceSelector />
{/if}
