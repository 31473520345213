import { format, isToday, parseISO } from "date-fns";
import { derived, type Readable } from "svelte/store";
import {
  fetchPolicy,
  fetchProperty,
  fetchSpaceStatus,
  fetchUnits,
} from "./api";
import { param, params } from "./params";

export interface Policy {
  scope: string;
  policy: string;
  title: string;
  info?: {
    text: string;
  };
  issue?: {
    enabled: boolean;
  };
  vehicle?: {
    request?: boolean;
    required?: boolean;
    items?: any[];
    item?: any;
  };
  media?: {
    request?: boolean;
    required?: boolean;
    items?: any[];
    item?: any;
  };
  space?: {
    request?: boolean;
    required?: boolean;
    items?: any[];
    item?: any;
  };
  tenant?: {
    request?: boolean;
    required?: boolean;
    items?: any[];
    item?: any;
  };
  units?: {
    items: Record<string, any>;
  };
  spaces?: {
    items: Record<string, any>;
  };
}

export interface Property {
  id: string;
  name: string;
  map?: string;
  logo?: any;
}

// ev: function (feature, value) {
//   return (
//     polygon(feature) && feature.properties["capacity:charging"] == value
//   );
// },
// ada: function (feature, value) {
//   return (
//     polygon(feature) && feature.properties["capacity:disabled"] == value
//   );
// },

// const highlight = {
//   ev: [
//     "all",
//     ["==", ["geometry-type"], "Polygon"],
//     ["has", "capacity:charging"],
//   ],
//   ada: [
//     "all",
//     ["==", ["geometry-type"], "Polygon"],
//     ["has", "capacity:disabled"],
//   ],
// };

// export const filters = {
//   "ev":function(feature, value) {
//       return polygon(feature) && feature.properties["capacity:charging"] == value;
//   },
//   "ada":function(feature, value) {
//       return polygon(feature) && feature.properties["capacity:disabled"] == value;
//   },
//   "policy":function(feature, policy) {
//       return false;
//   },
//   "parking_space":function(feature, value) {
//       return polygon(feature) && feature.properties["amenity"] == "parking_space" && (!value || feature.properties["parking_space"] == value);
//   },
//   "access":function(feature, value) {
//       return polygon(feature) && feature.properties["access"] == value;
//   },
//   "size":function(feature, value) {
//       return polygon(feature) && feature.properties["size"] == value;
//   },

// };

// let highlighters = [
//   {
//     key: "ev",
//     value: "yes",
//     evaluator: filters["ev"],
//     title: "Charging",
//     count: 0,
//     active: false,
//   },
//   {
//     key: "ada",
//     value: "yes",
//     evaluator: filters["ada"],
//     title: "ADA",
//     count: 0,
//     active: false,
//   },
//   {
//     key: "compact",
//     value: "yes",
//     evaluator: (feature) => filters["size"](feature, "compact"),
//     title: "Compact",
//     count: 0,
//     active: false,
//   },
//   {
//     key: "tandem",
//     value: "yes",
//     evaluator: (feature) =>
//       filters["size"](feature, "multiple") ||
//       filters["size"](feature, "tandem"),
//     title: "Tandem",
//     count: 0,
//     active: false,
//   },
//   {
//     key: "prospect",
//     value: "prospect",
//     evaluator: filters["access"],
//     title: "Leasing",
//     count: 0,
//     active: false,
//   },
//   {
//     key: "customer",
//     value: "customer",
//     evaluator: filters["access"],
//     title: "Retail",
//     count: 0,
//     active: false,
//   },
//   {
//     key: "parking_space",
//     value: "yes",
//     evaluator: (feature) => filters["parking_space"](feature),
//     title: "Spaces",
//     count: 0,
//     active: false,
//   },
//   {
//     key: "motorcycle",
//     value: "yes",
//     evaluator: (feature) => filters["parking_space"](feature, "motorcycle"),
//     title: "Motorcycle",
//     count: 0,
//     active: false,
//   },
//];

export const policyId = param("policy");
export const level = param("level") as Readable<string | null>;
export const valid = param("valid", true, format(new Date(), "yyyy-MM-dd"));

// export const highlighters = derived<Readable<Record<string, string>>, any[]>(
//   params,
//   ($params) => {
//     const filters: any[] = [];

//     for (const [k, v] of Object.entries($params ?? {})) {
//       if (highlight[k]) {
//         filters.push(highlight[k]);
//       }
//     }

//     if (filters.length > 1) filters.unshift("any");

//     console.log("filters=", filters);

//     return filters.length !== 1 ? filters : filters[0];
//   }
// );

export const policy = derived<Readable<string>, Policy | null>(
  policyId,
  function updater($policyId, set) {
    if (!$policyId) return set(null);
    fetchPolicy($policyId).then(set);
  }
);

export const propertyId = derived<[Readable<string>, typeof policy], string>(
  [param("property"), policy],
  ([$id, $policy]) => $id || ($policy?.scope as string)
);

export const property = derived(propertyId, function updater($propertyId, set) {
  if (!$propertyId) return set(null);
  fetchProperty($propertyId).then(set);
}) as Readable<Property | null>;

export const units = derived<
  Readable<string | null>,
  Record<string, any> | null
>(propertyId, ($propertyId, set) => {
  if (!$propertyId) return set(null);

  fetchUnits($propertyId)
    .then(function (json) {
      const meta = json.units["for"][$propertyId];
      const items = meta.items;

      for (const [k, v] of Object.entries(items)) {
        items[k] = json.items[v as string] ?? json.items[k] ?? v;
      }

      return items;
    })
    .then(set);
});

const status = derived(
  [propertyId, valid],
  function updater([$propertyId, $valid], set) {
    if (!$propertyId) return set(null);
    // console.log(
    //   "$valid=",
    //   $valid,
    //   parseISO($valid),
    //   format(new Date($valid), "yyyy-MM-dd'T'00:00:00'/'")
    // );
    set(null);
    fetchSpaceStatus(
      $propertyId,
      isToday(new Date($valid as string))
        ? null
        : format(parseISO($valid as string), "yyyy-MM-dd'T'00:00:00'/'")
    ).then(set);
  }
) as Readable<any>;

export const spaces = derived(status, ($status, set) => {
  if (!$status) return set(null);

  set(
    Object.values($status.spaces.items).reduce(
      (result: Record<string, any>, item: string | any) => {
        item = $status.items[item as string] || item;

        for (const k1 of ["prices", "permitted"]) {
          item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
        }

        result[item.id] = item;

        return result;
      },
      {}
    )
  );
  // for (const item of Object.values($policy?.spaces?.items ?? {}) as any[]) {
  //   for (const k1 of ["prices", "permitted"]) {
  //     item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
  //   }
}) as Readable<Record<string, any> | null>;

export const spacesByLevel = derived([policy, status], ([$policy, $status]) => {
  if (null == $policy || null == $status) return null;

  const levels: Record<string, any> = {
    // outside: {
    //   level: "outside",
    //   "level:ref": "Outside",
    //   count: 0,
    //   available: 0,
    // },
  };

  for (const item of Object.values($policy?.spaces?.items ?? {}) as any[]) {
    for (const k1 of ["prices", "permitted"]) {
      item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
    }

    for (let l of item.level?.split ? item.level.split(";") : [""]) {
      if ("" == l) l = "outside";
      if (!levels[l])
        levels[l] = {
          "level:ref": `Floor ${l}`,
          items: {},
          level: l,
          count: 0,
          available: 0,
        };
      levels[l].count++;
      if (item["level:ref"]) levels[l]["level:ref"] = item["level:ref"];
      if (false !== item.permitted?.available) {
        levels[l].items[item.id] = item;
        levels[l].available++;
      }
    }
  }

  return levels;
});

export const availableSpaces = derived<
  [Readable<any>, Readable<any>],
  {
    count: number;
    items: Record<string, any>;
  } | null
>([policy, status], ([$policy, $status], set) => {
  if (null == $policy || null == $status) return set(null);

  set(
    Object.values($policy?.spaces?.items ?? {}).reduce(
      (result: any, item: any) => {
        for (const k1 of ["prices", "permitted"]) {
          item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
        }
        if (false !== item.permitted?.available) {
          result.count++;
          result.items[item.id] = item;
        }
        return result;
      },
      {
        count: 0,
        items: {},
      }
    ) as {
      count: number;
      items: Record<string, any>;
    }
  );
});
