<script context="module" lang="ts">
  import {
    property,
    policy,
    spaces,
    spacesByLevel,
    availableSpaces,
  } from "../util/propertystores";
</script>

<script lang="ts">
  import PropertyMap from "./PropertyMap.svelte";
  import MapLevelSelect from "./map/MapLevelSelect.svelte";
  import ParkingAvailabilityMenu from "./ParkingAvailabilityLevels.svelte";
  import ParkingAvailabilitySpaces from "./ParkingAvailabilitySpaces.svelte";
  import SpaceForm from "./SpaceForm.svelte";
  import { thumbnail } from "../util/filepreview";
  import type { MapGeoJSONFeature } from "./map/mapping";
  import MapPopup from "./map/MapPopup.svelte";
  import RecordItem from "./RecordItem.svelte";
  import MapFeatureState from "./map/MapFeatureState.svelte";
  import { writable, type Writable } from "svelte/store";
  import { hover } from "../util/behaviorstores";
  import MapFeatureHover from "./map/MapFeatureHover.svelte";
  import MapFeatureClick from "./map/MapFeatureClick.svelte";
  let level: Writable<string> = writable<string>();
  let levels: Record<string, any>;
  let selected: Record<string, boolean>;
  let hovered: MapGeoJSONFeature;

  $: console.log("level=", level);

  $: console.log("property=", $property);
  $: console.log("policy=", $policy);
  $: console.log("levels=", $spacesByLevel);
  $: console.log("available=", $availableSpaces);

  $: title =
    $property && $policy
      ? `${$policy.title} - ${$property.name}`
      : "Loading...";

  $: document.title = title;

  $: if (null != $level) selected = {};

  $: selectedSpaces = Object.entries(selected ?? {})
    .filter(([k, v]) => !!v)
    .map(([k, v]) => $availableSpaces?.items[k]);

  $: hoveredSpace =
    $availableSpaces?.items[hovered?.properties?.["ref:boss:subject"]];

  //$: console.log("hovered space=", hovered, hoveredSpace);
</script>

{#if $property?.map}
  <PropertyMap
    property={$property?.id}
    bind:level={$level}
    bind:levels
    minzoom={17}
  >
    <slot />

    <MapFeatureState
      state={Object.values($availableSpaces?.items ?? {}).reduce(
        (result, item) => {
          //if (item.permitted) {
          result[item.id] = {
            permit: item.permitted?.permitted ? "yes" : "no",
            selectable: true,
            selected: selected?.[item.id],
          };
          //}
          return result;
        },
        {}
      )}
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        [
          "in",
          ["get", "ref:boss:subject"],
          ["literal", Object.keys($availableSpaces?.items ?? {})],
        ],
      ]}
    />
    {#if $hover}
      <MapFeatureHover
        filter={[
          "all",
          ["==", ["geometry-type"], "Polygon"],
          [
            "in",
            ["get", "ref:boss:subject"],
            ["literal", Object.keys($availableSpaces?.items ?? {})],
          ],
        ]}
        bind:hovered
      >
        {#if hoveredSpace}
          <MapPopup feature={hovered}>
            <h1>
              <button
                class="record"
                name="selected"
                value={hoveredSpace.id}
                type="button"
                on:click={(e) => (selected = { [e.currentTarget.value]: true })}
                ><RecordItem item={hoveredSpace} /></button
              >
            </h1>
            {#if hoveredSpace.prices}
              <p>{hoveredSpace.prices.description}</p>
            {/if}
          </MapPopup>
        {/if}
      </MapFeatureHover>
    {/if}
    <MapFeatureClick
      filter={[
        "all",
        ["==", ["geometry-type"], "Polygon"],
        [
          "in",
          ["get", "ref:boss:subject"],
          ["literal", Object.keys($availableSpaces?.items ?? {})],
        ],
      ]}
      on:click={(e) => {
        console.log("map click=", e);
        selected = { [e.detail.properties["ref:boss:subject"]]: true };
      }}
    />
  </PropertyMap>
{/if}
<main>
  {#if $property?.map}
    <header>
      <h1>
        <data class="property" value={$property.id}>{$property.name}</data>
        <data class="policy" value={$policy.policy}>{$policy.title}</data>
      </h1>
      <ParkingAvailabilityMenu
        policy={$policy}
        bind:level={$level}
        availability={$spacesByLevel}
      />
    </header>

    {#if selectedSpaces.length}
      <SpaceForm bind:selected policy={$policy} />
    {:else if $availableSpaces?.count}
      <ParkingAvailabilitySpaces
        policy={$policy}
        level={$level}
        availability={$spacesByLevel}
        bind:selected
      />
    {/if}
  {/if}
  <footer>
    {#if $property?.logo}
      <figure class="logo">
        <img
          class="logo"
          alt="{$property.name} logo"
          src={thumbnail($property.logo)}
        />
      </figure>
    {/if}
  </footer>
</main>
