<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { Readable, Writable } from "svelte/store";
  import { sortBy } from "../util/sort";
  import Loading from "./Loading.svelte";
  import RecordItem from "./RecordItem.svelte";
  import Warning from "./Warning.svelte";

  const dispatchEvent = createEventDispatcher();

  export let policy: any;
  export let level: string;
  export let availability: Record<
    string,
    {
      level: string;
      "level:ref": string;
      available: number;
      count: number;
      items: Record<string, any>;
    }
  >;
  let byLevel: {
    level: string;
    "level:ref": string;
    available: number;
    count: number;
    items: Record<string, any>;
  };
  export let selected: Record<string, boolean>;
  $: byLevel = availability?.[level];

  function recordclick(e: Event) {
    const button = e.currentTarget as HTMLButtonElement;
    selected = {
      [button.value]: true,
    };
  }
</script>

<nav>
  {#if !policy || !availability}
    <Loading message="Loading available spaces" />
  {:else if byLevel}
    <header>
      <h1>Available Spaces</h1>
    </header>
    <ul class="items">
      {#each Object.values(byLevel.items) as item}
        <li>
          <button
            class="record"
            type="button"
            name="selected"
            value={item.id}
            on:click={recordclick}><RecordItem {item} /></button
          >
        </li>
      {/each}
    </ul>
  {/if}
</nav>
<!-- form-y stuff -->
