<script>
  import { debounce, orderBy, uniqBy, get } from "lodash-es";
  import MiniSearch from "minisearch";
  import { onDestroy, tick } from "svelte";
  import RecordItem from "../RecordItem.svelte";
  //import { geo } from "@parkingboss/svelte-utils";

  export let selected = null;
  export let property = null;
  export let required = false;
  export let scannable = false;

  export let items = [];
  import { vehicle as match } from "./select";

  export let placeholder = "License Plate/Tag";
  export let label = null;
  let scan = false;
  let scanning = false;
  let preview;
  let type = "vehicle";
  let datalist = null;

  let searched;
  let results = [];

  let cam;

  //$: console.log("match=", $match);
  //$: console.log("geo=", $geo);

  $: if (cam) cam.scrollIntoView();

  const miniSearch = new MiniSearch({
    fields: ["display"],
    searchOptions: {
      prefix: true,
      boost: { display: 2 },
    },
    storeFields: ["id", "key"],
    extractField: (document, fieldName) =>
      fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
  });

  $: itemsById = (items || []).reduce((map, item) => {
    return (map[item.id] = item) && map;
  }, {});

  //   $: if(items) name = capitalize([...new Set(items.map(i => i.format))].join(", "));

  $: autogenerated = $match
    ? [
        {
          type,
          id: $match.toUpperCase().replace(/[^A-Z0-9]/gi, ""),
          display: $match.toUpperCase().replace(/[^A-Z0-9]/gi, ""),
        },
      ]
    : [];

  $: searched =
    items &&
    $match &&
    uniqBy(
      orderBy(miniSearch.search($match), ["score", "display"], ["desc", "asc"]),
      "id"
    );
  $: results = autogenerated.concat(
    (searched && searched.map((result) => itemsById[result.id])) || []
  );

  // $: console.log("results=", results);

  $: if (items) {
    miniSearch.removeAll();
    miniSearch.addAll(items);
  } else {
    miniSearch.removeAll();
  }

  const oninput = debounce(
    function (e) {
      // console.log("suggest=", miniSearch.autoSuggest(e.currentTarget.value));
      // console.log("search=", miniSearch.search(e.currentTarget.value));
      //("about to set match store = ", e.currentTarget.value);
      match.set(e.currentTarget.value);
    },
    150,
    {
      leading: true,
      trailing: true,
    }
  );

  $: if (selected) match.set("");

  function clearPreview() {
    if (!preview) return;

    const revoke = preview;
    preview = null;
    URL.revokeObjectURL(revoke);
  }

  onDestroy(function () {
    match.set("");
    clearPreview();
  });

  function select(value) {
    match.set("");
    clearPreview();
    selected = value;
  }
</script>

<fieldset class="record {type}">
  {#if label}
    <label for="{type}-search">{label}</label>
  {/if}
  <input
    id="vehicle-search"
    name="vehicle"
    value={$match}
    maxlength="10"
    {placeholder}
    type="text"
    on:blur={oninput}
    on:input={oninput}
    on:change={oninput}
    required={!!required}
    autocorrect="off"
    autocomplete="off"
    autocapitalize="characters"
    spellcheck="false"
    list={datalist}
  />
  {#if datalist && items?.length}
    <select on:change={(e) => select(e.currentTarget.value)}>
      <option value="">---</option>
      {#each items as item}
        <option value={item.id}>{item.display}</option>
      {/each}
    </select>
    {#if datalist}
      <datalist id={datalist}>
        {#each items as item}
          <option value={item.display} />
        {/each}
      </datalist>
    {/if}
  {/if}
  <!-- {#if scannable}
      <button
        class="scan vehicle"
        class:active={scan}
        class:scanning
        type="button"
        on:click={(e) => (scan = !scan)}
      >
      </button>
    {/if} -->
</fieldset>
<ul class="results">
  {#each results as item}
    <li>
      <button
        class="record"
        type="button"
        value={item.id || item.display}
        on:click={(e) => select(item)}><RecordItem {item} url={false} /></button
      >
    </li>
  {/each}
</ul>
