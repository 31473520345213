<script context="module">
  import identity from "lodash-es/identity";
</script>

<script>
  export let item;
  export let url = false;
</script>

<data
  class={[
    "id",
    item.type,
    item._type,
    item.format,
    item.color?.toLowerCase().replace(" ", "-"),
  ]
    .filter(identity)
    .join(" ")}
  value={item.id || item.key}
  >{item.display}{#if item.color}<data
      class="color"
      value={item.color.toLowerCase()}
    />{/if}{#each ["capacity:charging", "capacity:disabled", "capacity:lift", "size"].filter((key) => !!item[key]) as key}<data
      class={key.replace(":", "-")}
      value={item[key]}
    />{/each}</data
>
