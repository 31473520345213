<script lang="ts">
  // import { get } from "lodash-es";
  // import { query } from "../util/router";
  // import { units } from "../util/datastores";
  // import SelectUnit from "./SelectUnit.svelte";
  // import SelectVehicle from "./SelectVehicle.svelte";
  // import SelectMedia from "./SelectMedia.svelte";
  import SpaceField from "./forms/SpaceField.svelte";
  import MediaField from "./forms/MediaField.svelte";
  import TenantField from "./forms/TenantField.svelte";
  import VehicleField from "./forms/VehicleField.svelte";
  import type { Policy } from "../util/propertystores";
  import Info from "./Info.svelte";

  export let policy: Policy = null;
  export let space: any = null;
  export let vehicle: any = null;
  export let unit: any = null;
  export let media: any = null;

  let submitting = false;
  let submittable = false;
  let form;

  // async function submit(e) {
  //   e.preventDefault();

  //   if (submitting) return;
  //   submitting = true;

  //   submitting = submittable = false;
  // }

  $: submittable =
    form &&
    (vehicle || !policy.vehicle || !policy.vehicle.required) &&
    (unit || !policy.tenant || !policy.tenant.required) &&
    (space || !policy.space || !policy.space.required);
  //&& form.checkValidity();
</script>

{#if policy && policy.issue && policy.issue.enabled}
  <form
    bind:this={form}
    method="get"
    action="https://my.parkingattendant.app/{policy.scope}/permits/temporary/new"
  >
    <slot><header><h1>{policy.title}</h1></header></slot>

    <input type="hidden" name="property" value={policy.scope} />
    <input type="hidden" name="policy" value={policy.policy} />

    {#if space}
      <input type="hidden" name="space" value={space.id} />
    {:else if policy?.space?.required}
      <SpaceField
        bind:value={space}
        items={Object.values(policy.spaces.items ?? {})}
        readonly={!!space}
        required={policy.space.required}
      />
    {/if}
    {#if policy?.media?.required}
      <MediaField
        bind:value={media}
        items={Object.values(policy.media.items ?? {})}
        required={policy.media.required}
      />
    {/if}
    {#if policy?.vehicle?.required}
      <VehicleField bind:value={vehicle} required={policy.vehicle.required} />
    {/if}
    {#if policy?.vehicle?.request && !policy?.vehicle?.required}
      <VehicleField required={policy.vehicle.required} />
    {/if}
    {#if policy?.tenant?.request}
      <TenantField
        bind:value={unit}
        items={Object.values(policy.units.items ?? {})}
        required={policy.tenant.required}
      />
    {/if}
    <!-- {#if policy?.space?.request && !policy?.space?.required}
  <SpaceField
    value={policy.space.item || ($spaceId && $spaceStatus?.spaces.item)}
    items={policy.space.items ?? Object.values($spaces?.items ?? {})}
    required={policy.space.required}
  />
{/if} -->

    {#if policy?.media?.request && !policy?.media?.required}
      <MediaField
        bind:value={media}
        items={Object.values(policy.media.items ?? {})}
        required={policy.media.required}
      />
    {/if}

    <!-- {#if policy.vehicle.request}
      {#if vehicle}
        <ul>
          <li>
            <input type="hidden" name="vehicle" value={vehicle.id || vehicle} />
            <data class="vehicle id" value={vehicle.id || vehicle}
              >{vehicle.display || vehicle}</data
            >
            <button type="button" on:click={(e) => (vehicle = null)}
              >change</button
            >
          </li>
        </ul>
      {:else}
        <fieldset class="vehicle">
          <SelectVehicle
            scannable={true}
            required={policy.vehicle.required}
            {property}
            bind:selected={vehicle}
          />
        </fieldset>
      {/if}
    {/if}

    {#if policy.media.request}
      {#if media}
        <ul>
          <li>
            <input type="hidden" name="media" value={media.id || media} />
            <data
              class="media {media.format || ''} id"
              value={media.id || media}>{media.display || media}</data
            >
            <button type="button" on:click={(e) => (media = null)}
              >change</button
            >
          </li>
        </ul>
      {:else}
        <fieldset class="media">
          <SelectMedia
            required={policy.media.required}
            {property}
            bind:selected={media}
          />
        </fieldset>
      {/if}
    {/if}

    {#if policy.tenant.request}
      {#if unit}
        <ul>
          <li>
            <input
              type="hidden"
              name="tenant"
              value={unit.key || unit.id || unit}
            />
            <data class="unit id {unit.format + ''}" value={unit.id || unit}
              >{unit.display || unit}</data
            >
            <button type="button" on:click={(e) => (unit = null)}>change</button
            >
          </li>
        </ul>
      {:else}
        <fieldset class="unit">
          <SelectUnit
            required={policy.tenant.required}
            items={$units}
            bind:selected={unit}
          />
        </fieldset>
      {/if}
    {/if}

    {#if policy.space.request}
      {#if space}
        <input
          type="hidden"
          name="space"
          value={space.key || space.id || space}
        />
      {/if}
    {/if} -->

    <!-- {#if !!get(policy, "tel.request", false) || !!get(policy, "email.request", false)}
        <fieldset class="contact">
            <p>Contact info:</p>
            <ul>
            {#if !!get(policy, "name.request", false)}
            <li>
                <label for="permit-name">Name</label><input id="permit-name" type="text" name="name" value="" spellcheck="false"  placeholder={!!get(policy, "name.required", false) ? "required" : "optional"} required={!!get(policy, "name.required", false)} autocomplete="name">
            </li>
            {/if}
            {#if !!get(policy, "tel.request", false)}
            <li>
                <label for="permit-tel">Phone</label><input id="permit-tel" type="tel" name="tel" placeholder={!!get(policy, "tel.required", false) ? "required" : "optional"} required={!!get(policy, "tel.required", false)} value="" spellcheck="false" autocomplete="tel" />
            </li>
            {/if}
            {#if !!get(policy, "email.request", false)}
            <li>
                <label for="permit-email">Email</label><input id="permit-email" type="email" name="email" placeholder={!!get(policy, "email.required", false) ? "required" : "optional"} required={!!get(policy, "email.required", false)} value="" spellcheck="false" autocomplete="email">
            </li>
            {/if}
            </ul>
        </fieldset>
    {/if} -->

    <footer>
      <button disabled={!submittable} type="submit">Continue</button>
      <Info message={policy.info?.text} />
    </footer>
  </form>
{:else if policy}
  <h1>Policy is disabled</h1>
{/if}
