<script lang="ts">
  import RecordItem from "../RecordItem.svelte";
  import Select from "../records/SelectMedia.svelte";

  export let value = null;
  export let name = "media";
  export let required = false;
  export let readonly = false;
  export let scannable = false;
  export let items = [];
  export let label = "Decal";
  export let placeholder: string = null;

  $: id = (value?.id || value)?.toString();
</script>

{#if id}
  <figure class="value record">
    {#if label}
      <figcaption>{label}</figcaption>
    {/if}
    <input type="hidden" {name} value={id} />
    <RecordItem item={value} url={false} />
    {#if !readonly}
      <button type="button" on:click={(e) => (value = null)}>change</button>
    {/if}
  </figure>
{:else}
  <Select
    placeholder={required ? "Required" : "Optional"}
    {label}
    {required}
    {items}
    bind:selected={value}
    {scannable}
  />
{/if}
