<script lang="ts">
  import { availableSpaces, type Policy } from "../util/propertystores";
  import Info from "./Info.svelte";
  import NewPermit from "./NewPermit.svelte";
  import RecordItem from "./RecordItem.svelte";

  export let selected: Record<string, any>;
  export let policy: Policy;

  $: selectedSpaces = Object.entries(selected ?? {})
    .filter(([k, v]) => !!v)
    .map(([k, v]) => $availableSpaces?.items[k]);

  $: space = selectedSpaces[0];
</script>

<NewPermit {space} {policy}>
  <header>
    <h1><RecordItem item={space} /></h1>
    <button type="button" on:click={(e) => (selected = {})}>change</button>
  </header>
  <Info
    class="price record"
    title="Price"
    message={space?.prices?.description}
  />
</NewPermit>

<!-- <form on:reset={(e) => (selected = {})}>
  <header />

  {#each selectedSpaces as item}
    <RecordItem {item} />
  {/each}
</form> -->
